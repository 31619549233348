import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import onCallLogo from '../../assets/brandmark_oncall.png';
import buttonUpdater from '../hooks/buttonUpdater';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);

    const navItems = [
        { title: "Home", href: "/OnCall" },
        { title: "Our Story", href: "/OurStory" },
        { title: "For Professionals", href: "/ForNurses" },
        { title: "For Facilities", href: "/Facilities" },
    ];

    const headerNavs = [
        { title: "Sign Up", href: "#11" },
        { title: "Log in", href: "#22" }
    ];

    const toggleMenu = () => setIsMenuOpen(prevState => !prevState);

    useEffect(() => {
        const closeDropdowns = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', closeDropdowns);
        return () => {
            document.removeEventListener('mousedown', closeDropdowns);
        };
    }, []);

    const handleButtonClick = buttonUpdater();

    return (
        <header className="fixed top-0 left-0 right-0 bg-OnCallcolor1 py-2 px-4 sm:px-6 rounded-full mx-auto mt-4 flex justify-between items-center z-50 shadow-md max-w-[90%] sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-5xl">
            <Link to="/OnCall"><img src={onCallLogo} alt="OnCall Logo" className="h-6 md:h-8" /></Link>
            <nav className="hidden md:flex space-x-4 lg:space-x-6 flex-grow justify-center">
                {navItems.map((item, index) => (
                    <Link key={index} to={item.href} className="text-2xl md:text-base text-gray-700 font-bold hover:text-gray-900">{item.title}</Link>
                ))}
            </nav>
            <div className="hidden md:flex space-x-2">
                {headerNavs.map((nav, index) => (
                    <button key={index} className="bg-OnCallcolor3 text-white px-3 py-1 md:px-4 md:py-2 rounded-full text-xs font-bold md:text-sm" onClick={() => handleButtonClick(nav.title)}>
                        {nav.title}
                    </button>
                ))}
            </div>
            <button ref={buttonRef} className="md:hidden navbar-burger flex items-center text-white p-2" onClick={toggleMenu}>
                <svg className="block h-4 w-4 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <title>Mobile menu</title>
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                </svg>
            </button>
            <div ref={menuRef} className={`navbar-menu fixed inset-0 z-50 md:hidden ${isMenuOpen ? '' : 'hidden'}`}>
                <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25" onClick={toggleMenu}></div>
                <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-l overflow-y-auto">
                    <div className="flex items-center mb-8">
                        <Link to="/OnCall" className="mr-auto text-3xl font-bold leading-none">
                            <img className="h-8 w-auto" src={onCallLogo} alt="OnCall" />
                        </Link>
                        <button className="navbar-close" onClick={toggleMenu}>
                            <svg className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </button>
                    </div>
                    <ul>
                        {navItems.map((nav, index) => (
                            <li className="mb-1" key={index}>
                                <Link to={nav.href} className="block p-3 text-sm font-semibold text-black hover:bg-blue-50 hover:text-OnCallcolor3 rounded" onClick={toggleMenu}>
                                    {nav.title}
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <div className="mt-auto">
                        <div className="pt-6">
                            {headerNavs.map((nav, index) => (
                                <a key={index} className="block px-3 py-2 mb-2 leading-loose text-xs md:text-sm text-center text-white font-semibold bg-OnCallcolor3 hover:bg-OnCallcolor2 rounded-xl" href={nav.href} onClick={() => handleButtonClick(nav.title)}>
                                    {nav.title}
                                </a>
                            ))}
                        </div>
                        <p className="my-4 text-xs text-center text-gray-400">
                            <span>Copyright © 2024</span>
                        </p>
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Header;