import React from 'react';
import ourStoryBanner from '../../assets/story_banner.png';
import nurseImage from '../../assets/mission_heart.jpg';
import joshImage from '../../assets/josh_fam.png';
import StoryCarousel from './storyCarousel';
import Neme from '../../assets/Nemesis.jpg';
import Emmanuel from '../../assets/Emmanuel.jpg';
import Chad from '../../assets/Chad.jpg';
import Josh from '../../assets/JoshMic.jpg';
import useLazyBackgroundImage from '../../component/hooks/LazyloadImage';


function OurStory() {
    let slide = [
        { image: Josh, title: 'Joshua Michaels',
        description: `Joshua is OnCall's team lead and marketing director. From founding organizations for young musicians to launching a startup for nurses, he is a dynamic force of innovation and dedication.`,},

        { image: Neme, title: 'Nemesis "Neme" Velazquez',
        description: `Neme Velazquez is the UI/UX Designer for our platform. She uses her skills in both programming and art to create interactive experiences that keep up with emerging technologies.`,},

        { image: Chad, title: 'Chad Chapman', description: `Chad Chapman is the backend engineer for our platform. Fascinated with computers and programming from a young age, he is a computer scientist possessing interests that span the field of computing with focuses on software engineering, system administration, and cybersecurity.`,},

        { image: Emmanuel, title: 'Emmanuel Boakye', description: `Emmanuel Boakye is the front-end engineer for our platform. He has always had a fascination for Neuroscience and it's intersection with technology.`,},];

        
    const bgImage = useLazyBackgroundImage(ourStoryBanner);

    return (
        <div className="text-gray-800">
            {/* Header Section */}
            <header className="bg-cover bg-center object-fill" style={{ height: '500px', backgroundImage: bgImage ? `url(${bgImage})` : 'none', }}>
            </header>

            {/* Our Story Section */}
            <section id="our-story" className="p-20 bg-white">
                <div className="max-w-4xl mx-auto">
                    <div className="relative">
                        <img
                            src={joshImage} loading='lazy'
                            alt="Joshua 'Josh' Michaels"
                            className="w-60 h-60 rounded-full float-right mr-10 mb-10"
                        />
                        <p className="text-2xl text-black">
                            The idea of OnCall started in 2019 when founder
                            Joshua Michaels witnessed his mother’s struggle to
                            find coverage for her nursing home where she was a
                            staffing coordinator. “At the time, being a staffing
                            coordinator was like being a magician because you
                            had to make nurses appear out of nowhere,” said
                            Ms. Michaels.</p>
                        <p className="mt-4 text-2xl text-black">
                            It was later that year that Joshua worked his first job at
                            a nursing home where he saw, firsthand, the issues affecting nurses
                            and how nurses are one of the most important, yet undervalued positions
                            in the healthcare industry.</p>
                        <blockquote className="mt-4 p-4 bg-gray-100 border-l-4 border-OnCallcolor3">
                            <p className="text-lg italic font-bold">“It was a 24/7 job. Often she was working on weekends,
                                holidays- even on my birthday. The core idea of OnCall was to develop a tool that could help get that time back.”</p>
                            <cite className="block mt-2 text-right font-black">- Joshua Michaels, Founder</cite>
                        </blockquote>
                    </div>
                </div>
            </section>

            {/* Our Mission Section */}
            <section id='our-mission' className="p-8 bg-OnCallcolor2 text-white">
                <div className="max-w-4xl mx-auto flex flex-col md:flex-row">
                    <div className="md:w-1/2 flex justify-center">
                        <img src={nurseImage} alt="Nurse" className="shadow-md" />
                    </div>
                    <div className="md:w-1/2 md:ml-8 mt-4 md:mt-0">
                        <h2 className="text-5xl font-bold">Our Mission</h2>
                        <p className="text-md mt-5 text-2xl">
                            At OnCall, we believe that nurses are the undervalued cornerstone of the healthcare industry. For decades,
                            the nursing workforce has been exploited by a multi-billion dollar industry without fair compensation.
                            OnCall gives the power back to the workers to change the future of healthcare for the better.
                        </p>
                        <p className="text-2xl text-center mt-10">
                            “Taking care of the nurses who take care of us.”
                        </p>
                    </div>
                </div>
            </section>

            {/* Meet the Team Section */}
            <section id='meet-the-team' className="p-8 bg-gray-100">
                <div className="max-w-4xl mx-auto">
                    <h2 className="text-5xl font-bold text-center text-gray-800 mb-5">Meet the Team</h2>
                    <StoryCarousel slides={slide} />
                </div>
            </section>
        </div>
    );
}

export default OurStory;

