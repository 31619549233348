import React from 'react';
import nurseImage from '../../assets/homepage_Nurse.jpg';
import curveImage from '../../assets/FINAL_DIVIDE.png';
import { Link } from 'react-router-dom';

const HomeSection = () => {
  return (
    <section className="relative bg-white h-screen overflow-hidden">
      <div className="absolute inset-0 flex">
        <div className="md:w-full bg-white relative z-10 flex-col items-center">
          <div className="relative max-w-7xl mx-auto h-full flex items-center">
            <div className="w-full md:w-3/4 pr-4 sm:pr-8 md:pl-40 z-20">
              <h1 className="text-5xl sm:text-5xl md:text-5xl font-bold mb-2 sm:mb-3 ml-5 sm:ml-4">Staffing Made Simple</h1>
              <p className="text-2xl sm:text-2xl md:text-2xl mb-3 sm:mb-5 text-gray-600 max-w-md ml-5 sm:ml-4">
                OnCall pairs nurses to their local healthcare facilities with the most speed and flexibility so you can focus on serving your community.
              </p>
              <div className="flex space-x-2 sm:space-x-4 ml-5 sm:ml-4">
                <Link to="/ForNurses"><button className="bg-OnCallcolor2 text-white text-sm sm:text-lg md:text-xl px-4 sm:px-6 py-1 sm:py-2 rounded-full hover:bg-teal-700 transition duration-300">Caregivers</button></Link>
                <Link to="/Facilities"><button className="bg-white border border-teal-600 text-sm sm:text-lg md:text-xl text-teal-600 px-4 sm:px-6 py-1 sm:py-2 rounded-full hover:bg-teal-50 transition duration-300">Facilities</button></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 relative z-0 md:z-20">
          <img src={nurseImage} loading="lazy" alt="Nurse with patient" className="h-full w-full object-cover" />
          <img src={curveImage} loading="lazy" alt="Curve Divider" className="absolute top-0 right-3/4 h-full object-fill z-10" style={{ transform: 'translateX(20%)' }} />
        </div>
      </div>
    </section>
  );
}

export default HomeSection; 