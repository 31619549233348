import { useEffect, useState } from 'react';

const useLazyBackgroundImage = (imageUrl) => {
  const [bgImage, setBgImage] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      setBgImage(imageUrl);
    };
  }, [imageUrl]);

  return bgImage;
};

export default useLazyBackgroundImage;
