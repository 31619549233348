import { useState } from "react";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";


export default function HowCarousel({ slides }) {
    const [current, setCurrent] = useState(0);

    const previousSlide = () => {
        setCurrent(current === 0 ? slides.length - 1 : current - 1);
    };

    const nextSlide = () => {
        setCurrent(current === slides.length - 1 ? 0 : current + 1);
    };

    return (
        <div className="overflow-hidden relative max-w-4xl mx-auto">
        <div
            className="flex transition-transform ease-out duration-500"
            style={{
                transform: `translateX(-${current * 100}%)`,
            }}
        >
            {slides.map((slide, index) => (
                <div key={index} className="flex-none w-full flex flex-col items-center bg-OnCallcolor2 p-14">
                    <h3 className="text-2xl md:text-4xl font-bold text-white mb-5">{slide.title}</h3>
                    <img src={slide.image} alt={slide.title} className="w-full h-full object-cover" />
                </div>
            ))}
        </div>

            <div className="absolute top-0 h-full w-full flex justify-between items-center text-white px-4">
                <button onClick={previousSlide}>
                    <MdArrowBackIos size={28} />
                </button>
                <button onClick={nextSlide}>
                    <MdArrowForwardIos size={28} />
                </button>
            </div>

            <div className="absolute bottom-0 py-4 flex justify-center gap-3 w-full">
                {slides.map((_, i) => (
                    <div
                        key={i}
                        onClick={() => setCurrent(i)}
                        className={`w-10 h-2 cursor-pointer transition-all ${
                            i === current ? "bg-white" : "bg-gray-500"
                        }`}
                    ></div>
                ))}
            </div>
        </div>
    );
}
