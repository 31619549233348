import { useCallback } from 'react';

const useButtonAlert = () => {
  const handleButtonClick = useCallback((buttonTitle) => {
    let message = '';
    if (buttonTitle === 'Sign Up') {
      message = 'Sign Up not integrated yet, still in developmental phase.';
    } else if (buttonTitle === 'Log in') {
      message = 'Log in feature not integrated yet, still in developmental phase.';
    }
    window.alert(message);
  }, []);

  return handleButtonClick;
};

export default useButtonAlert;