import React from 'react';
import coverImage from '../../assets/facilbanner.png';
import timeLine1 from '../../assets/coverage_bullet.png';
import timeLine2 from '../../assets/cost_bullet.png';
import timeLine3 from '../../assets/local_bullet.png';
import timeline from '../../assets/timeline.png';
import bannerCov from '../../assets/bannerDoc.jpg';
import useLazyBackgroundImage from '../../component/hooks/LazyloadImage';
import HowCarousel from '../../pages/Our-Story/howCarousel.jsx';
import facilSetup from '../../assets/facil_carousel1.png';
import facilPost from '../../assets/facil_carousel2.png';
import facilPair from '../../assets/facil_carousel3.png';


export default function FacilitiesContent() {
    let featureContent = [
        {image: facilSetup, title: 'Setup Facility Staff and Documents',},
        {image: facilPost, title: 'Post Shifts',},
        {image: facilPair, title: 'Wait for Paired Nurse'}
    ];
    
    const bgImage = useLazyBackgroundImage(coverImage);

    return (
        <div className="bg-white">
            {/* Header Section */}
            <div className="relative">
                <div
                    className="relative flex items-center justify-center bg-cover object-cover bg-center"
                    style={{ height: '100vh', minHeight: '400px', backgroundImage: bgImage ? `url(${bgImage})` : 'none', }}
                >
                    <div className="absolute left-4 md:left-20 top-72 max-w-md text-left p-4 md:p-0">
                        <h1 className="text-5xl md:text-5xl font-bold text-black">Get Back to Care, Not the Calendar</h1>
                        <p className="text-2xl md:text-2xl text-black font-bold mt-4">
                            OnCall enables you and your facility to provide the best care with immediate coverage and verified nurses.
                        </p>
                    </div>
                </div>
            </div>

            {/* What You Can Expect Section */}
            <div className="text-center my-16 px-4">
                <h2 className="text-3xl md:text-4xl font-bold mb-10 md:mb-20">What You Can Expect</h2>
                <div className="flex flex-col md:flex-row justify-center">
                    {/* Content wrapper */}
                    <div className="flex flex-col md:flex-row">
                        {/* Left column */}
                        <div className="flex flex-col items-center md:items-end mb-8 md:mb-0 md:mr-4">
                            <div className="flex flex-col items-center mb-8 md:mb-36">
                                <img src={timeLine1} alt="Flexible Schedule" className="w-64 h-54 object-fill rounded-3xl" />
                            </div>
                            <div className="flex flex-col items-center mb-8 md:mb-24">
                                <div className="w-64 text-center md:text-left">
                                    <h3 className="text-xl md:text-3xl font-bold mb-2">Reduced Cost</h3>
                                    <p className="text-gray-700 text-2xl">No more adding bonuses to get shifts filled. No more understaffing fines. Just care. Plain and simple.</p>
                                </div>
                            </div>
                            <div className="flex flex-col items-center">
                                <img src={timeLine3} alt="Retainer Pay" className="w-64 h-54 object-cover rounded-3xl mb-4" />
                            </div>
                        </div>

                        {/* Timeline column*/}
                        <div className="hidden md:flex relative flex-col items-center mx-10 mt-10">
                            <img src={timeline} alt="Timeline" className="h-5/6" />
                        </div>

                        {/* Right column */}
                        <div className="flex flex-col items-center md:items-start md:ml-4">
                            <div className="flex flex-col items-center mb-8 md:mb-48">
                                <div className="w-full max-w-sm text-center md:text-left">
                                    <h3 className="text-2xl md:text-3xl font-bold mb-2 whitespace-nowrap">Immediate Coverage</h3>
                                    <p className="text-gray-700 text-xl md:text-2xl">Short for your next shift? Request a nurse and have them there within the hour.</p>
                                </div>
                            </div>
                            <div className="flex flex-col items-center mb-8 md:mb-24">
                                <img src={timeLine2} alt="Your Shift, Guaranteed" className="w-64 h-54 object-fill rounded-3xl mb-4" />
                            </div>
                            <div className="flex flex-col items-center">
                                <div className="w-64 text-center md:text-left">
                                    <h3 className="text-2xl md:text-3xl font-bold mb-2">Local Talent</h3>
                                    <p className="text-gray-700 text-2xl">Thanks to our system, you’ll get the best nearby nurses, meaning more familiar faces and less training.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

                    {/* Meet the Team Section */}
                    <section id='how-it-works' className="p-8">
                <div className="max-w-4xl mx-auto">
                    <h2 className="text-5xl font-bold text-center text-gray-800 mb-5">How It Works</h2>
                    <HowCarousel slides={featureContent} />
                </div>
            </section>

            {/* Call to Action Section */}
            <div className="flex flex-col md:flex-row justify-between items-center bg-OnCallcolor2 text-white mb-10">
                <div className="text-center md:text-left mb-6 md:mb-0 px-4 md:px-48 pt-10">
                    <h2 className="text-3xl md:text-5xl font-bold mb-4">On board with OnCall?</h2>
                    <p className="text-md md:text-2xl mb-6">Reach out today to see what we can do for your facility!</p>
                    {/* a tag subject to change after a better contact us logic in implemented*/}
                    <a href='mailto:info@oncall.health'><button className="bg-white text-blue-800 font-bold py-2 px-4 mb-2 rounded-3xl">
                        Contact Us
                    </button></a>
                </div>
                <div className="w-full md:w-1/3 md:mr-20">
                    <img
                        src={bannerCov}
                        alt="Nurse ready to be OnCall"
                        className="w-full"
                    />
                </div>
            </div>
        </div>
    )
}