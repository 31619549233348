import React from 'react';
import './index.css';
import Header from './component/Header/landing-header';
import HomeSection from './pages/Home/landing-home';
import Footer from './component/Footer/landing-footer';
import { Routes, Route, Navigate, BrowserRouter} from 'react-router-dom'; 
import OurStory from './pages/Our-Story/ourStory';
import ForNurses from './pages/For-Professionals/fornurses';
import FacilitiesContent from './pages/For-Facilities/facilitiesContent';
import ScrollToTop from './component/hooks/scrollToTop';

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <BrowserRouter>
      <ScrollToTop />
        <Header />
        <main className="flex-grow">
          <Routes>
          <Route path="/" element={<Navigate to="/OnCall"/>} /> 
            <Route path='/OnCall' element={<HomeSection />} />
            <Route path="/OurStory" element={<OurStory />} />
            <Route path="/ForNurses" element={<ForNurses/>}/>
            <Route path="/Facilities" element={<FacilitiesContent/>}/>
          </Routes>
        </main>
      <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
