import React from 'react';
import nurseHeader from '../../assets/nurseheader.jpg';
import timeline from '../../assets/timeline.png';
import nurseImage1 from '../../assets/flexSched_bulleyt.png';
import nurseImage2 from '../../assets/yourShift_bullet.png';
import nurseImage3 from '../../assets/retainer_bullet.png';
import onCallImage from '../../assets/join_banner.jpg';
import HowCarousel from '../../pages/Our-Story/howCarousel.jsx';
import nurseManage from '../../assets/nurseManage.png';
import nurseCredential from '../../assets/NurseCreden.png';
import useLazyBackgroundImage from '../../component/hooks/LazyloadImage.jsx';
import buttonUpdater from '../../component/hooks/buttonUpdater.jsx';
import nursePrep from '../../assets/nurse_carousel3.png';

export default function ForNurses() {
    let featureContent = [
        {image: nurseCredential, title: 'Upload Your Licenses & Credentials',},
        {image: nurseManage, title: 'Manage Your Schedule',},
        {image: nursePrep, title: 'Done! Prepare for Your Next Assignment'}
    ];

    const handleButtonClick = buttonUpdater();

    const bgImage = useLazyBackgroundImage(nurseHeader);

    return (
        <div className="bg-white">
            {/* Header Section */}
            <div className="relative">
                <div
                    className="relative flex items-center justify-center bg-cover object-cover bg-center"
                    style={{ height: '100vh', minHeight: '400px', backgroundImage: bgImage ? `url(${bgImage})` : 'none', }}
                >
                    <div className="absolute left-4 md:left-20 top-72 max-w-md text-left p-4 md:p-0" >
                        <h1 className="text-5xl md:text-5xl font-bold text-white md:text-black">Take Control of Your Career</h1>
                        <p className="text-2xl md:text-2xl text-white md:text-black font-bold mt-4">
                            OnCall gives nurses the tools to succeed with a schedule tailored to their needs.
                        </p>
                    </div>
                </div>
            </div>

            {/* What You Can Expect Section */}
            <div className="text-center my-16 px-4">
                <h2 className="text-3xl md:text-4xl font-bold mb-10 md:mb-20">What You Can Expect</h2>
                <div className="flex flex-col md:flex-row justify-center">
                    {/* Content wrapper */}
                    <div className="flex flex-col md:flex-row">
                        {/* Left column */}
                        <div className="flex flex-col items-center md:items-end mb-8 md:mb-0 md:mr-4">
                            <div className="flex flex-col items-center mb-8 md:mb-36">
                                <img src={nurseImage1} alt="Flexible Schedule" className="w-64 h-54 object-fill rounded-3xl" />
                            </div>
                            <div className="flex flex-col items-center mb-8 md:mb-24">
                                <div className="w-64 text-center md:text-left">
                                    <h3 className="text-2xl md:text-3xl font-bold mb-2">Your Shift, Guaranteed</h3>
                                    <p className="text-gray-700 text-xl">No more competing for shifts. If assigned to a shift, it's yours.</p>
                                </div>
                            </div>
                            <div className="flex flex-col items-center">
                                <img src={nurseImage3} alt="Retainer Pay" className="w-64 h-54 object-cover rounded-3xl mb-4" />
                            </div>
                        </div>

                        {/* Timeline column*/}
                        <div className="hidden md:flex relative flex-col items-center mx-10 mt-10">
                            <img src={timeline} alt="Timeline" className="h-5/6" />
                        </div>

                        {/* Right column */}
                        <div className="flex flex-col items-center md:items-start md:ml-4">
                            <div className="flex flex-col items-center mb-8 md:mb-48">      
                                <div className="w-64 text-center md:text-left">
                                    <h3 className="text-2xl md:text-3xl font-bold mb-2">Flexible Schedule</h3>
                                    <p className="text-gray-700 text-xl">You select your work days and we assign you the shift. Simple as that.</p>
                                </div>
                            </div>
                            <div className="flex flex-col items-center mb-8 md:mb-24">
                                <img src={nurseImage2} alt="Your Shift, Guaranteed" className="w-64 h-54 object-fill rounded-3xl mb-4" />
                            </div>
                            <div className="flex flex-col items-center">
                                <div className="w-64 text-center md:text-left">
                                    <h3 className="text-2xl md:text-3xl font-bold mb-2">Retainer Pay</h3>
                                    <p className="text-gray-700 text-xl">Even if you aren't selected for a shift, you can still get paid, just for your time!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Meet the Team Section */}
            <section id='how-it-works' className="p-8">
                <div className="max-w-4xl mx-auto">
                    <h2 className="text-5xl font-bold text-center text-gray-800 mb-5">How It Works</h2>
                    <HowCarousel slides={featureContent} />
                </div>
            </section>

           {/* Call to Action Section */}
           <div className="flex flex-col md:flex-row justify-between items-center bg-OnCallcolor2 text-white mb-10">
                <div className="text-center md:text-left mb-6 md:mb-0 px-4 md:px-48 pt-10">
                    <h2 className="text-3xl md:text-5xl font-bold mb-4">Ready to be OnCall?</h2>
                    <p className="text-md md:text-2xl mb-6">Join today to see the difference!</p>
                    <button className="bg-white text-blue-800 font-bold text-xl py-2 px-10 mb-2 rounded-3xl" onClick={() => handleButtonClick('Sign Up')}>
                        Sign Up
                    </button>
                </div>
                <div className="w-full md:w-1/3 md:mr-20">
                    <img 
                        src={onCallImage} 
                        alt="Nurse ready to be OnCall" 
                        className="w-full"
                    />
                </div>
            </div>
        </div>
    );
}