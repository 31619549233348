import React from 'react';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
    const footerLinks = [
        { title: "Support", links: [
            { label: "info@oncall.health", href: "mailto:info@oncall.health" }]},

        { title: "About Us", links: [
            { label: "Our Story", href: "/OurStory#our-story" }, 
            { label: "OnCall Mission", href: "/OurStory#our-mission" }, 
            { label: "Meet the Team", href: "/OurStory#meet-the-team" }]},
        
        { title: "Professionals", href: "/ForNurses", links: [] },

        { title: "Providers", href: "/Facilities", links: [] }
    ];

    return (
        <footer className="bg-OnCallcolor3 text-white py-8 mt-auto text-center">
            <div className="container mx-auto px-10 flex flex-col md:flex-row justify-between">
                {footerLinks.map((section, index) => (
                    <div className="mb-4 md:mb-0" key={index}>
                        {section.href ? (
                            <HashLink to={section.href} className="font-bold text-white">
                                {section.title}
                            </HashLink>
                        ) : (
                            <h5 className="font-bold mb-2">{section.title}</h5>
                        )}
                        <ul>
                            {section.links.map((link, linkIndex) => (
                                <li className="mb-2" key={linkIndex}>
                                    {link.href.startsWith('mailto:') ? (
                                        <a href={link.href} className="text-white underline">
                                            {link.label}
                                        </a>
                                    ) : (
                                        <HashLink 
                                            to={link.href}
                                            className="text-white"
                                            smooth
                                        >
                                            {link.label}
                                        </HashLink>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
            <div className="text-center mt-8 text-sm">
                © 2024 OnCall | All rights reserved
            </div>
        </footer>
    );
};

export default Footer;